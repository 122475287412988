import { Xmark } from "@/assets/Icons";
import img from "@/assets/SeoImg.webp";
import Image from 'next/image';


function Banner({close, setClose}) {
   
    return (
        <>
            {!close && <div className="hidden   md:flex justify-between py-2 px-4  items-center bg-black">
                <div className="flex  flex-row items-center">
                    <div className="h-6 w-6">
                        <Image src={img} alt='' className='w-full h-full object-cover rounded-full' width={500} height={500} />
                    </div>
                    <p className="text-sm text-[#c1bbbb] pl-4">🌟 Special Announcement: Enjoy 70% off on Autodesk services this month! 🌟</p>
                </div>
                <Xmark onClick={() => setClose(true)} />
            </div>}
        </>
    )
}

export default Banner