

const Input = ({
    type,
    placeholder,
    name,
    className,
    icon,
    label,
    inputContainerStyle=""
  }) => {
    return (
      <div className={inputContainerStyle} >
        <label className="text-base font-medium text-gray-900">{label}</label>
        <div className=" relative text-gray-400 focus-within:text-gray-600 w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none w-full">
            {icon}
          </div>
          <input
            type={type}
            autoComplete="off"
            placeholder={placeholder}
            className={className + " relative mr-5 inline-block rounded-xl border font-semibold transition-all ease-in-out duration-300 hover:border-black md:mr-6  focus:shadow-none [box-shadow:#EC1C1C_6px_6px]"} 
            name={name}
            spellCheck="false"
          />
        </div>
      </div>
    );
  };
  
  export default Input;