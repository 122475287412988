import img from "@/assets/SeoImg.webp";
import Image from 'next/image';
import { useState } from "react";
import Input from "../uikit/Input";
import { motion, AnimatePresence } from 'framer-motion';



function NewsLetter() {
    const [notification, setNotification] = useState({
        show: false,
        type: 'success', // or 'error'
        message: '',
    });

    const [sending, setSending] = useState(null)
  
    const handleSubscribe = async (e) => {

        e.preventDefault(); // Prevents the default form submission


        const formData = new FormData(e.target);
        formData.append('name','newsLetter')

        try {
        
            setSending(true)
            const response = await fetch("https://script.google.com/macros/s/AKfycbz2w1qTGWxC-kYRO0FwOvd0DWVrbCd-YtL2uey5hpqHBTpFVO7gLGYe0ODGuLxO_hCq/exec", {
                method: "POST",
                body: formData,
              });
          

            const data = await response.json();
            setSending(false)
            e.target.reset(); // Clear the form
            setNotification({
                show: true,
                type: 'success',
                message: 'Thank you for subscribing !!',
            });
          } catch (error) {
            console.error('Error:', error.message);
            setSending(false)

            setNotification({
                show: true,
                type: 'error',
                message: 'Subscription failed. Please try again.',
            });

          }

          setTimeout(() => {
            setNotification({show : false});
        }, 3000);
      };
    

    return (

        <section className="z-50">
            <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 ">
                <div className="grid items-center gap-16 bg-[#f2f2f7] rounded-xl p-8 sm:p-10 md:grid-cols-[0.5fr_1fr] md:p-16">
                    <Image src={img} alt='' className='w-full h-full rounded-lg object-cover' width={500} height={500} />
                    <div className="max-w-xl">
                        <h2 className="mb-4 text-3xl font-bold md:text-4xl">Join the YourCad Community</h2>
                        <p className="mb-6 text-sm text-[#636262] sm:text-base md:mb-10 lg:mb-12">🚀 Welcome to the YourCad Community Newsletter! 🌐 Stay at the forefront of design innovation with our exclusive newsletter. Unleash your creativity, discover industry insights, and receive the latest updates on affordable Autodesk services tailored to your budget.</p>
                        <div className="mb-4 flex">
                            <form onSubmit={handleSubscribe} className="flex w-full flex-col gap-3 sm:flex-row">
                                <Input
                                    type="email"
                                    placeholder={"Enter your email"}
                                    name="email"
                                    className="py-2 px-5 md:py-2 md:px-3 border border-black w-full"
                                    icon=""
                                    label=""
                                    inputContainerStyle="w-full sm:w-3/6 "
                                />
                                <button
                                     type="submit"
                                    className="border-[#EC1C1C] px-8 py-2 w-full sm:w-auto md:w-auto  bg-black text-[#fff] relative hover:shadow-none [box-shadow:#EC1C1C_6px_6px] mr-5 inline-block rounded-xl border   text-center font-semibold   transition-all ease-in-out duration-300 hover:border-black md:mr-6 "
                                >
                                    {sending == null ? "Notify me" : sending ? "Sending ..." : "Done"}
                                    
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fixed top-0 left-0 w-full">
                <AnimatePresence>
                    {notification.show && (
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            transition={{ duration: 0.5 }}
                            className={`p-4 text-center ${
                                notification.type === 'success'
                                    ? 'bg-green-500 text-white'
                                    : 'bg-red-500 text-white'
                            }`}
                        >
                            {notification.message}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </section>
    )
}

export default NewsLetter