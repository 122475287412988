import logo from "@/assets/logo.webp";
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { twMerge } from "tailwind-merge";
import Btn from '../uikit/Btn';
import Banner from "./Banner";

import dynamic from 'next/dynamic';
const PopUp = dynamic(() => import('../uikit/PopUp'), { ssr: false });

const links = [
  {
    link: "/",
    text: "Home"
  },
  {
    link: "/softwares",
    text: "Softwares"
  },
  {
    link: "/blog",
    text: "Blog"
  },
  {
    link: "/about",
    text: "About"
  },
  {
    link: "/faq",
    text: "FAQs"
  },
]

function Nav() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMediumScreen = useMediaQuery({ maxWidth: 1023 })
  // navbar state
  const [isOpen, setIsOpen] = useState(false);
  // banner state
  const [close, setClose] = useState(false)

  return (

    <section>
      <Banner close={close} setClose={setClose} />
      {isOpen && <PopUp isOpen={isOpen} setIsOpen={setIsOpen} />}
      <div className="h-auto w-screen  mx-auto">
        <nav className="font-inter mx-auto h-auto w-full max-w-[1600px] lg:relative lg:top-0" x-data="{isOpen: false, menuOne:false}">
          <div className="flex flex-col px-6 py-6 lg:flex-row lg:items-center lg:justify-between lg:px-10 lg:py-4 xl:px-20">
            <Link title="logo" href="/" className=" w-fit" aria-label="Logo" >
              <Image width={150} height={150} src={logo} alt="logo" className="inline-block  object-cover" />
            </Link>
            <motion.div initial={{ height: 0, opacity: 0 }}
              animate={{ height: !isMediumScreen ? "auto" : isMobileMenuOpen ? 'auto' : 1, opacity: !isMediumScreen ? 1 : isMobileMenuOpen && isMediumScreen ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              className={` flex flex-col space-y-8 lg:mt-0 lg:flex lg:flex-row lg:space-x-1 lg:space-y-0   ${!isMediumScreen ? "text-black  " : isMobileMenuOpen && isMediumScreen ? ' mt-14' : 'mt-0  text-white -z-50 opacity-0'}`}  >
              {
                links.map((e, i) => (
                  <Link title={e.text} onClick={() => setMobileMenuOpen(false)} key={i} href={e.link} className="font-inter w-fit rounded-lg lg:px-6 lg:py-1 nav__li ">{e.text}</Link>
                ))
              }
              <div className={twMerge("flex flex-col space-y-8 lg:flex lg:flex-row lg:space-x-3 lg:space-y-0  ", !isMediumScreen ? " lg:hidden " : isMobileMenuOpen && isMediumScreen ? ' transition-all duration-300 ease-in-out ' : ' text-white -z-50 opacity-0 ')} >
                <Btn text={"Contact Us"} link={"/contact"} />
              </div>
            </motion.div>

            <div className=" flex-col space-y-8  lg:flex-row lg:space-x-3 lg:space-y-0 hidden lg:flex" >
              <button
                aria-label="Contact Us"
                onClick={() => setIsOpen(true)}
                className="border-[#EC1C1C] px-8 py-2 w-full bg-[#EC1C1C] text-[#fff] relative hover:shadow-none [box-shadow:#000_6px_6px] mr-5 inline-block rounded-xl border   text-center font-semibold   transition-all ease-in-out duration-300 hover:border-black md:mr-6 "
              >
                Contact Us
              </button>
              {/* <div id="google_translate_element" className=''></div>  */}
            </div>
            <button
              aria-label="hamburger menu"
              className={`absolute right-5 top-14  lg:hidden ${!close && " md:top-24 "} `}
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            >
              <motion.svg
                animate={{ rotate: isMobileMenuOpen ? 45 : 0 }}
                transition={{ duration: 0.3 }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3.75 12H20.25" stroke="#160042" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.75 6H20.25" stroke="#160042" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.75 18H20.25" stroke="#160042" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </motion.svg>
            </button>
          </div>

        </nav>
      </div >
      <Link title="whatsApp_button" aria-label="whatsApp_button" href='https://wa.link/2melq1' className="md:p-5 p-3 fixed md:bottom-10 bottom-5 md:left-auto left-5 md:right-10 z-30 rounded-full text-white bg-green-600">
        <svg className="text-white md:w-10 w-8" fill="white" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M11.42 9.49c-.19-.09-1.1-.54-1.27-.61s-.29-.09-.42.1-.48.6-.59.73-.21.14-.4 0a5.13 5.13 0 0 1-1.49-.92 5.25 5.25 0 0 1-1-1.29c-.11-.18 0-.28.08-.38s.18-.21.28-.32a1.39 1.39 0 0 0 .18-.31.38.38 0 0 0 0-.33c0-.09-.42-1-.58-1.37s-.3-.32-.41-.32h-.4a.72.72 0 0 0-.5.23 2.1 2.1 0 0 0-.65 1.55A3.59 3.59 0 0 0 5 8.2 8.32 8.32 0 0 0 8.19 11c.44.19.78.3 1.05.39a2.53 2.53 0 0 0 1.17.07 1.93 1.93 0 0 0 1.26-.88 1.67 1.67 0 0 0 .11-.88c-.05-.07-.17-.12-.36-.21z"></path><path d="M13.29 2.68A7.36 7.36 0 0 0 8 .5a7.44 7.44 0 0 0-6.41 11.15l-1 3.85 3.94-1a7.4 7.4 0 0 0 3.55.9H8a7.44 7.44 0 0 0 5.29-12.72zM8 14.12a6.12 6.12 0 0 1-3.15-.87l-.22-.13-2.34.61.62-2.28-.14-.23a6.18 6.18 0 0 1 9.6-7.65 6.12 6.12 0 0 1 1.81 4.37A6.19 6.19 0 0 1 8 14.12z"></path></g></svg>
      </Link>
    </section>
  )
}

export default Nav