

export const Links = [
    // {
    //   link:"/about",
    //   text:"About"
    // },
    // {
    //   link:"/blog",
    //   text:"Blog"
    // },
    // {
    //   link:"/softwares",
    //   text:"Softwares"
    // },
    {
      link:"/privacy-policy",
      text:"Privacy Policy"
    },
    {
      link:"/billing-terms-and-conditions",
      text:"Billing Terms And Conditions"
    },
    {
      link:"/cookie-policy",
      text:"Cookie Policy"
    },
    {
      link:"/order-cancellation-policy",
      text:"Order Cancellation Policy"
    },
    {
      link:"/refound-and-returns-policy",
      text:"Refound And Returns Policy"
    },
    {
      link:"/shipping-policy",
      text:"Shipping Policy"
    },
    {
      link:"/terms-of-service",
      text:"Terms Of Service"
    }
]

