import Footer from "@/components/Layout/Footer";
import Nav from "@/components/Layout/Nav";
import ChatwootWidget from "@/components/uikit/ChatBot";
import "@/styles/globals.css";
import { NextIntlClientProvider } from "next-intl";
import Image from "next/image";


export default function App({ Component, pageProps,  }) {

  
  return (
    <NextIntlClientProvider messages={pageProps.messages}  >
      {/* <GeneralSeo /> */}
      <ChatwootWidget />
      {/* <AnimatePresence mode="wait">
        <motion.div key={route.pathname}> */}
          <Nav />
          <Image
                  src={"/badge.png"}
                  alt={"badge"}
                  width={700}
                  height={700}
                  quality={100}
                  priority
                  draggable="false"
                  className=" bottom-2  md:bottom-5 fixed right-0 md:left-5 z-[9999] h-20 md:h-28 w-20 object-contain select-none "
                />
          <Component {...pageProps} />
          {/* <Animation /> */}
          <Footer />
        {/* </motion.div>
      </AnimatePresence> */}
    </NextIntlClientProvider>
  );
}
