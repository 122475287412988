import Link from 'next/link'
import React from 'react'
import logo from "@/assets/logo.webp"
import Image from 'next/image'
import { Links } from '@/data/footer'
import NewsLetter from './NewsLetter'


function Footer() {
  
  return (
     
<footer className="block">
  <NewsLetter />
  <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 ">
    <div className="flex flex-col items-center">
      <Link href="/" className="mb-8 inline-block max-w-full text-black" aria-label="Logo">
        <Image width={150} height={150} src={logo} alt="" className="inline-block  object-cover" />
      </Link>
      <div className=" max-w-3xl mx-auto text-center font-semibold max-[991px]:py-1">
        {Links.map((e,i) => (
          <Link key={i} href={e.link} className="inline-block px-6 py-2 font-normal text-black transition hover:text-[#EC1C1C]">{e.text}</Link>

        ))}
      </div>
      <div className="mb-8 mt-8 w-48 [border-bottom:1px_solid_rgb(0,_0,_0)]"></div>
      <div className="mb-12 grid w-full max-w-[208px] grid-flow-col grid-cols-4 gap-3">
        <Link href="#" className="mx-auto flex max-w-[24px] flex-col items-center justify-center" aria-label="facebook">
          <Image width={100} height={100} src="https://swc.autodesk.com/pharmacopeia/svg/icons/v0/icons/social/facebook-circle.svg" alt=""  className="inline-block" />
        </Link>
        <Link href="#" className="mx-auto flex max-w-[24px] flex-col items-center justify-center" aria-label="instagram">
          <Image width={100} height={100} src="https://swc.autodesk.com/pharmacopeia/svg/icons/v0/icons/social/instagram-circle.svg" alt="" className="inline-block" />
        </Link>
        <Link href="#" className="mx-auto flex max-w-[24px] flex-col items-center justify-center" aria-label="linkedin">
          <Image width={100} height={100} src="https://swc.autodesk.com/pharmacopeia/svg/icons/v0/icons/social/linkedin-circle.svg" alt="" className="inline-block" />
        </Link>
        <Link href="#" className="mx-auto flex max-w-[24px] flex-col items-center justify-center" aria-label="twitter">
          <Image width={100} height={100} src="https://swc.autodesk.com/pharmacopeia/svg/icons/v0/icons/social/twitter-circle.svg" alt="" className="inline-block" />
        </Link>
      </div>
      <p className="max-[479px]:text-sm">© Copyright {new Date().getFullYear()} All rights reserved.</p>
    </div>
  </div>
</footer>
  )
}

export default Footer